import { createTheme, lighten } from '@mui/material/styles'
import { getAnalytics } from "firebase/analytics"
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore"
import { connectFunctionsEmulator, getFunctions, httpsCallable } from 'firebase/functions'

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const functions = getFunctions(app, 'northamerica-northeast1')
export const db: any = getFirestore(app)

if (window.location.hostname === 'localhost') {
    connectFirestoreEmulator(db, "localhost", 8086)
    connectFunctionsEmulator(functions, "localhost", 5008)
}

export const pubkey = String(process.env.REACT_APP_PUBKEY)
export const mockPubkey = String(process.env.REACT_APP_MOCKPUBKEY)
export const pubkeyAlgo = String(process.env.REACT_APP_PUBKEY_ALGO)

export const theme = createTheme({
    palette: {
        primary: {
            main: '#000000',
            light: lighten('#000000', 0.5),
            dark: '#000000'
        },
        background: {
            default: '#f8f8f8',
            paper: '#f8f8f8'
        }
    }
})

function getFunctionsUrl() {
    return process.env.REACT_APP_FUNCTIONS_URL
}

export const analytics = getAnalytics(app)
export const functionsUrl = getFunctionsUrl()
export const brandUrl = process.env.REACT_APP_BRAND_URL
export const pcApi = httpsCallable(functions, 'api')