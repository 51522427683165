import { logEvent } from "firebase/analytics"
import { analytics, pcApi } from "../config"
import { IContact } from "../interfaces/IContact"
import { TToken } from "../types/TToken"

export class ContactModel {
    readonly id: string
    readonly email: string
    readonly status: string
    readonly token: string
    readonly initialInterests: string[]
    customAttributes: { name: string, value: string }[]
    interests: string[]

    private constructor({ id, status, email, token, customAttributes, interests }: IContact) {
        this.id = id
        this.email = email
        this.status = status
        this.token = token
        this.customAttributes = customAttributes
        this.initialInterests = interests ? JSON.parse(JSON.stringify(interests)) : []
        this.interests = interests ? JSON.parse(JSON.stringify(interests)) : []
    }

    static async get({ token }: TToken): Promise<ContactModel> {
        return pcApi({ action: 'getContact', cryptotext: token })
            .then((response: any) => {
                const data = response.data
                return new ContactModel({
                    id: data.id,
                    email: data.email,
                    status: data.status,
                    token: token,
                    customAttributes: data.customAttributes || [],
                    interests: data.interests || []
                })
            })
            .catch((error: any) => {
                logEvent(analytics, 'error', error)
                throw error
            })
    }

    async save(): Promise<ContactModel> {
        return pcApi({ action: 'setContact', cryptotext: this.token, customAttributes: this.customAttributes, interests: this.interests })
            .then((response: any) => {
                return new ContactModel({
                    id: this.id,
                    email: this.email,
                    status: this.status,
                    token: this.token,
                    customAttributes: this.customAttributes,
                    interests: this.interests
                })
            })
            .catch((error: any) => {
                logEvent(analytics, 'error', error)
                throw error
            })
    }

    async unsubscribeFromList(): Promise<ContactModel> {
        return pcApi({ action: 'unsubscribeContact', cryptotext: this.token })
            .then((response: any) => {
                const data = response.data
                logEvent(analytics, 'unsubscribe', { email: this.email })
                return new ContactModel({
                    id: data.id,
                    email: data.email,
                    status: data.status,
                    token: this.token,
                    customAttributes: data.customAttributes,
                    interests: data.interests
                })

            })
            .catch((error: any) => {
                logEvent(analytics, 'error', error)
                throw error
            })
    }

    async resubscribeToList(): Promise<ContactModel> {
        return pcApi({ action: 'resubscribeContact', cryptotext: this.token })
            .then((response: any) => {
                const data = response.data
                logEvent(analytics, 'resubscribe', { email: this.email })
                return new ContactModel({
                    id: data.id,
                    email: data.email,
                    status: data.status,
                    token: this.token,
                    customAttributes: data.customAttributes,
                    interests: data.interests
                })
            })
            .catch((error: any) => {
                logEvent(analytics, 'error', error)
                throw error
            })
    }

    async isGlobalUnsubscribed(): Promise<boolean> {
        return pcApi({ action: 'isGlobalUnsubscribed', cryptotext: this.token })
            .then((response: any) => {
                return response.isGlobalUnsubscribed === true
            })
            .catch((error: any) => {
                logEvent(analytics, 'error', error)
                throw error
            })
    }
}
