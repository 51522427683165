import * as jose from 'jose'
import { mockPubkey, pubkey, pubkeyAlgo } from '../config'

export class Token {
    readonly accountId: number
    readonly listId: number
    readonly token: string

    private constructor({ accountId, listId, token }: Token) {
        this.accountId = accountId
        this.listId = listId
        this.token = token
    }

    static async decode(token: string): Promise<Token> {
        return this
            .verifyWithMainKey(token)
            .catch((error: any) => {
                return this
                    .verifyWithMockKey(token)
            })
            .catch((error: any) => {
                switch (error.code) {
                    case 'ERR_JWT_EXPIRED':
                        throw new Error("Link has expired")
                    case 'ERR_JWT_CLAIM_VALIDATION_FAILED':
                        throw new Error("Please wait...")
                }
                throw new Error("Invalid link")
            })
    }

    private static async verifyWithMainKey(token: string): Promise<Token> {
        return jose.importSPKI(pubkey, pubkeyAlgo)
            .then(async (jwt) => {
                return jose.jwtVerify(token, jwt)
                    .then((payload: any) => {
                        if (payload.payload.contactId === -1) {
                            throw new Error("Cannot use this token for mock")
                        }
                        return new Token({
                            accountId: payload.payload.accountId,
                            listId: payload.payload.listId,
                            token: token
                        })
                    })
            })
    }

    private static async verifyWithMockKey(token: string): Promise<Token> {
        return jose.importSPKI(mockPubkey, pubkeyAlgo)
            .then(async (jwt) => {
                return jose.jwtVerify(token, jwt)
                    .then((payload: any) => {
                        if (payload.payload.contactId !== -1) {
                            throw new Error("Invalid link")
                        }
                        return new Token({
                            accountId: payload.payload.accountId,
                            listId: payload.payload.listId,
                            token: token
                        })
                    })
            })
    }
}