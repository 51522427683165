export function LandingPage() {
    return <>
        <style>
            <link href='css/landing.css' rel='stylesheet' type='text/css' />
        </style>
        <div id="main-content">

            <h1>Welcome to<br />preference-centers.md02.com</h1>

            <p className="smallText">If you are reading this, you probably received an email from one of our clients. If this is
                not the case or you have reached this site in error, please double check the URL in your browser - you may
                have mistyped it.</p>

            <div id="spam-alert">
                <h3>Why am I receiving this SPAM?</h3>
                <p>If you have received this email from one of our clients and you do not remember signing up to receive it,
                    we want to know about it. Please contact our <a href="mailto:abuse@md02.com">Abuse Team</a> with details
                    about the email in question so we can investigate accordingly.</p>
            </div>

            <p>If you still have questions, or if you are having problems finding your way, <a
                href="mailto:abuse@md02.com">let us know</a>.</p>


        </div>
    </>
}