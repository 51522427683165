import { ThemeProvider } from '@mui/material/styles'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './App.css'
import { PreferenceCenterComponent } from './components/containers/PreferenceCenter'
import { theme } from './config'
import { LandingPage } from './components/containers/LandingPage'

function App() {
    return <>
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<LandingPage />} />
                    <Route path="/subscribe/:token" element={<PreferenceCenterComponent />} />
                </Routes>
            </BrowserRouter>
        </ThemeProvider>
    </>
}

export default App
