import { Container, Typography, useTheme } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { PreferenceCenterContext } from "../../contexts/PreferenceCenter"

export function CompanyAddress() {
    const context = useContext(PreferenceCenterContext)
    const [address, setAddress] = useState<string>()
    const theme = useTheme()

    function getAddress() {
        let address = ""
        const accountAddress = context?.account.address || {}
        if (context?.account) {
            if (accountAddress.address1) address += accountAddress.address1
            if (accountAddress.address2) address += " " + accountAddress.address2
            if (accountAddress.city) address += ", " + accountAddress.city
            if (accountAddress.province) address += ", " + accountAddress.province
            if (accountAddress.country) address += ", " + accountAddress.country
            if (accountAddress.postal_code) address += ", " + accountAddress.postal_code
            setAddress(address)
        }
    }

    useEffect(getAddress, [context])

    return <>
        <Container sx={{ paddingTop: 8 }}>
            <Typography fontSize={14} color={theme.palette.primary.light}>
                {address}
            </Typography>
        </Container>
    </>
}