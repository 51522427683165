import { logEvent } from "firebase/analytics"
import { analytics, pcApi } from "../config"
import { TToken } from "../types/TToken"

export class AccountModel {
    readonly id: number
    readonly name: string
    readonly lineage: string
    readonly address: any

    private constructor({ id, name, lineage, address }: AccountModel) {
        this.id = id
        this.name = name
        this.lineage = lineage
        this.address = address
    }

    static async get({ token }: TToken): Promise<AccountModel> {
        return pcApi({ action: 'getAccount', cryptotext: token })
            .then((response: any) => {
                const data = response.data
                return new AccountModel({
                    id: data.id,
                    name: data.name,
                    address: data.address,
                    lineage: data.lineage
                })
            })
            .catch((error: any) => {
                logEvent(analytics, 'error', error)
                throw Error('Error fetching account data')
            })
    }
}