import { logEvent } from "firebase/analytics"
import { analytics, pcApi } from "../config"
import { IPreferenceCenter } from "../interfaces/IPreferenceCenter"
import { IPreferenceCenterGetFirst } from "../interfaces/IPreferenceCenterGetFirst"
import { TCustomAttribute } from "../types/TCustomAttribute"
import { TInterest } from "../types/TInterest"

export class PreferenceCenterModel {
    readonly id: string
    readonly title: string
    readonly description: string
    readonly customAttributes: TCustomAttribute[]
    readonly interests: TInterest[]

    constructor({ id, title, description, customAttributes, interests }: IPreferenceCenter) {
        this.id = id
        this.title = title
        this.description = description
        this.customAttributes = customAttributes
        this.interests = interests
    }

    static async getFirstEnabled({ cryptotext }: IPreferenceCenterGetFirst): Promise<PreferenceCenterModel> {
        return pcApi({ action: 'getFirstPreferenceCenter', cryptotext })
            .then((response: any) => {
                const data = response.data
                return new PreferenceCenterModel({
                    id: data.id,
                    title: data.title,
                    description: data.description,
                    customAttributes: data.custom_attributes,
                    interests: data.interests
                })
            })
            .catch((error: any) => {
                logEvent(analytics, 'error', error)
                throw error
            })
    }
}
