import { Container, Typography } from "@mui/material"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { PreferenceCenterContext } from "../../contexts/PreferenceCenter"

export function Header() {
    const context = useContext(PreferenceCenterContext)
    const { t: translate } = useTranslation()

    return <>
        {!!context &&
            <Container sx={{ paddingBottom: 5 }}>
                <Typography
                    fontSize={30}
                    sx={{ paddingBottom: 1, paddingTop: 3 }}
                    color={'primary'}
                >
                    {translate('pref-center-title')}
                </Typography>
            </Container>
        }
    </>
}