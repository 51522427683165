export enum Translations {
    custom_attributes_title = 'custom_attributes_title',
    custom_attributes_description = 'custom_attributes_description',
    interests_title = 'interests-title',
    interests_description = 'interests-description',
    optin_title = 'optin-title',
    optin_description = 'optin-description',
    email_address = 'email-address',
    unsubscribe = 'unsubscribe',
    unsubscribe_from_list = 'unsubscribe-from-list',
    unsubscribe_from_all = 'unsubscribe-from-all',
    unsubscribe_from_all_lists = 'unsubscribe-from-all-lists',
    privacy_policy = 'privacy-policy',
    terms_of_service = 'terms-of-service',
}