import { Grid } from "@mui/material"
import { useContext } from "react"
import { PreferenceCenterContext } from "../../contexts/PreferenceCenter"

export function BrandedLogo() {
    const context = useContext(PreferenceCenterContext)

    return <>
        {context && context.brand?.data?.list_redirection?.logo?.url &&
            <Grid container display='flex' justifyContent="space-between" padding={4}>
                <Grid item>
                    <img
                        src={context.brand?.data?.list_redirection?.logo?.url || "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNk+M9QDwADhgGAWjR9awAAAABJRU5ErkJggg=="}
                        alt=""
                        style={{ width: context.brand?.data?.list_redirection?.logo?.width, maxWidth: 200, maxHeight: 200 }}
                    />
                </Grid>
            </Grid>
        }
    </>
}