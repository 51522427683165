import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

type Language = {
    value: string
    nativeName: string
}

export function LanguagePicker(): JSX.Element {
    const { t: translate, i18n } = useTranslation()
    const [language, setLanguage] = useState<string>('')
    const [languages, setLanguages] = useState<Language[]>([])

    function changeLanguage(event: SelectChangeEvent<string>): void {
        const lang = event.target.value
        if (localStorage) {
            localStorage.setItem('localeOverride', lang)
            localStorage.setItem('locale', lang)
        }
        i18n.changeLanguage(lang)
        setLanguage(lang)
    }

    function listLanguages(): void {
        if (i18n) {
            i18n.services
                .backendConnector
                .backend
                .getLanguages((err: any, languages: any) => {
                    setLanguages(Object.keys(languages).map(k => {
                        return {
                            value: k,
                            nativeName: translate(`language-name-${k}`)
                        }
                    }))
                })
        }
    }

    useEffect(listLanguages, [i18n, translate])

    return <>
        {languages &&
            <Grid container width={'100%'} display='flex' justifyContent='right' marginRight={2}>
                <Grid item>
                    <FormControl sx={{ minWidth: 120 }} size="small">
                        <InputLabel id="demo-select-small">{translate('language')}</InputLabel>
                        <Select id="language-select" value={language} label={translate('language')} onChange={changeLanguage} >
                            {languages?.map((language: Language) =>
                                <MenuItem key={`lmi-${language.value}`} value={language.value}>{language.nativeName}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        }
    </>
}