import { Theme, ThemeProvider, useTheme } from '@emotion/react'
import { Container, createTheme, CssBaseline, darken, Grid, Paper } from '@mui/material'
import Alert from '@mui/material/Alert'
import CircularProgress from '@mui/material/CircularProgress'
import { logEvent } from 'firebase/analytics'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { analytics } from '../../config'
import { PreferenceCenterContext } from '../../contexts/PreferenceCenter'
import { AccountModel } from "../../models/Account"
import { BrandModel } from '../../models/Brand'
import { ContactModel } from "../../models/Contact"
import { InterestModel } from '../../models/Interest'
import { Token } from '../../models/Token'
import { ListModel } from "../../models/List"
import { PreferenceCenterModel } from '../../models/PreferenceCenter'
import { BrandedLogo } from '../reusable/BrandedLogo'
import { CompanyAddress } from '../reusable/CompanyAddress'
import { Header } from '../reusable/Header'
import { PartnerLogo } from '../reusable/ResellerLogo'
import { PersonalInfo } from './CustomAttributes'
import { Interests } from './Interests'
import { LanguagePicker } from './LanguagePicker'
import { OptInSettings } from './OptInSettings'
import { PartnerLinks } from './PartnerLinks'

export function PreferenceCenterComponent(): JSX.Element {
    const [globalUnbuscribed, setGlobalUnsubscribed] = useState<boolean>(false)
    const [interests, setInterests] = useState<InterestModel[]>([])
    const [contact, setContact] = useState<ContactModel | null>(null)
    const [account, setAccount] = useState<AccountModel | null>(null)
    const [prefMgr, setPrefMgr] = useState<PreferenceCenterModel>()
    const [validToken, setValidToken] = useState<string>()
    const [list, setList] = useState<ListModel>()
    const [brand, setBrand] = useState<BrandModel | any>()
    const [partnerBrand, setPartnerBrand] = useState<BrandModel>()
    const [partnerUrl, setPartnerUrl] = useState<URL>()
    const [error, setError] = useState<any>()
    const [listUnsub, setListUnsub] = useState<boolean>(false)
    const { token } = useParams()
    const defaultTheme = useTheme()
    const [theme, setTheme] = useState<Theme>()
    const { i18n } = useTranslation()

    function loadPreferenceCenter(): void {
        if (validToken) {
            PreferenceCenterModel
                .getFirstEnabled({ cryptotext: validToken })
                .then((p: PreferenceCenterModel) => {
                    setPrefMgr(p)
                })
                .catch((error: any) => {
                    switch (error.message) {
                        case "Not found":
                            break
                        default:
                            setError("Error loading the preference center")
                            break
                    }
                })
        }
    }

    function loadBrand(): void {
        if (account) {
            BrandModel.get({ account: account })
                .then((brand: BrandModel) => {
                    setBrand(brand)
                })
                .catch((error: any) => {
                    setBrand({})
                })
        }
    }

    function loadList() {
        if (account && validToken) {
            ListModel.get({ token: validToken })
                .then((list: ListModel) => {
                    setList(list)
                    const localeOverride = localStorage?.getItem('localeOverride')
                    if (!localeOverride) {
                        i18n.changeLanguage(list.language.replace('_', '-'))
                    }
                })
        }
    }

    function loadInterests(): void {
        if (validToken) {
            InterestModel.list({ token: validToken })
                .then((interests: InterestModel[]) => {
                    setInterests(interests)
                })
                .catch((error: any) => {
                    setInterests([])
                })
        }
    }

    function loadContactData(): void {
        if (validToken) {
            ContactModel.get({ token: validToken })
                .then((c: ContactModel) => {
                    setContact(c)
                    if (c.status === 'unsubscribed') {
                        setListUnsub(true)
                    }
                })
                .catch((error: any) => {
                    setError("Error loading account information; try reloading the page")
                })
        }
    }

    function loadAccountData(): void {
        if (validToken) {
            AccountModel.get({ token: validToken })
                .then((a: AccountModel) => {
                    setAccount(a)
                })
                .catch((error: any) => {
                    setError("Error loading account information; try reloading the page")
                })
        }
    }

    function getResellerBrand(): void {
        if (account) {
            BrandModel.getBrandUrl({ account: account })
                .then((url: URL) => {
                    setPartnerUrl(url)
                    return BrandModel.get({ url: url })
                })
                .then((brand: BrandModel) => {
                    setPartnerBrand(brand)
                })
                .catch(() => {
                    // not a fatal error..  no reseller brand means no pricay policy link and no reseller logo
                })
        }
    }

    function handleSubmit(): void {
        if (contact) {
            contact.save()
        }
    }

    function handleListUnsub(event: any): void {
        const state = !listUnsub
        setListUnsub(state)
        if (contact) {
            if (state) {
                contact.unsubscribeFromList()
                    .then((c: ContactModel) => {
                        setContact(c)
                    })
            } else {
                contact.resubscribeToList()
                    .then((c: ContactModel) => {
                        setContact(c)
                    })
            }
        }
    }

    function flipUnsubAccordingToContact(): void {
        if (contact) {
            setListUnsub(contact.status !== 'active')
        }
    }

    function decodeToken(): void {
        if (token) {
            Token.decode(token)
                .then((decodedToken: Token) => {
                    setValidToken(decodedToken.token)
                })
                .catch((error: any) => {
                    logEvent(analytics, 'error', error)
                    setError(error.message)
                })
        }
    }

    function modifyTheme(): void {
        if (brand && brand.data?.list_redirection) {
            const titleColor = brand.data.list_redirection?.styles?.titleColor || brand.data.list_redirection?.styles['title-color'] || '#000000'
            const textColor = brand.data.list_redirection?.styles?.textColor || brand.data.list_redirection?.styles['text-color'] || '#000000'
            const backgroundColor = brand.data.list_redirection?.styles?.backgroundColor || brand.data.list_redirecton?.styles['background-color'] || '#f8f8f8'
            const darkMode = brand.data.list_redirection?.styles?.darkMode || brand.data.list_redirection?.styles['dark-mode'] || false

            setTheme(createTheme({
                palette: {
                    primary: {
                        main: titleColor,
                    },
                    secondary: {
                        main: titleColor,
                    },
                    action: {
                        disabled: textColor,
                    },
                    text: {
                        primary: textColor,
                        secondary: titleColor
                    },
                    background: {
                        default: backgroundColor,
                        paper: backgroundColor
                    },
                    divider: darken(textColor, 0.7),
                    warning: {
                        main: titleColor
                    },
                    mode: darkMode === true ? 'dark' : 'light'
                },
                typography: {
                    fontWeightBold: 500,
                    fontWeightRegular: 300
                },
            }))
        }
    }

    function loadGlobalUnsub() {
        if (contact) {
            contact.isGlobalUnsubscribed()
                .then((isGlobalUnsubd: boolean) => {
                    setGlobalUnsubscribed(isGlobalUnsubd)
                })
        }
    }

    useEffect(decodeToken, [token])
    useEffect(loadPreferenceCenter, [validToken])
    useEffect(loadContactData, [validToken])
    useEffect(loadAccountData, [validToken])
    useEffect(loadInterests, [validToken])
    useEffect(loadBrand, [account])
    useEffect(getResellerBrand, [account])
    useEffect(loadList, [account, validToken, i18n])
    useEffect(modifyTheme, [brand])
    useEffect(flipUnsubAccordingToContact, [contact])
    useEffect(loadGlobalUnsub, [contact])

    return <>
        <ThemeProvider theme={theme || defaultTheme}>
            <CssBaseline>
                <Container maxWidth={'md'}>
                    {!!!error && (!!!contact || !!!brand) &&
                        <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" style={{ minHeight: '100vh' }} >
                            <Grid item>
                                <CircularProgress color="inherit" />
                            </Grid>
                        </Grid>
                    }

                    {!!!error && !!contact && !!account && !!brand &&
                        <PreferenceCenterContext.Provider value={{
                            preferenceCenter: prefMgr,
                            contact: contact,
                            account: account,
                            list: list,
                            brand: brand,
                            interests: interests,
                            saveHandle: handleSubmit,
                            listUnsubd: listUnsub,
                            globalUnsubd: globalUnbuscribed,
                            partnerUrl: partnerUrl,
                            partnerBrand: partnerBrand
                        }}>
                            <>
                                {logEvent(analytics, 'subscribe_view', { accountId: account.id, contactId: contact.id })}
                                <Paper elevation={0} sx={{ marginTop: 2, marginBottom: 2, padding: 1, paddingLeft: 2, paddingBottom: 4 }} >
                                    <LanguagePicker />
                                    <BrandedLogo />
                                    <Header />
                                    <PersonalInfo />
                                    <Interests />
                                    <OptInSettings handleListUnsub={handleListUnsub} />
                                    <CompanyAddress />
                                    <PartnerLinks />
                                    <PartnerLogo />
                                </Paper>
                            </>
                        </PreferenceCenterContext.Provider>
                    }

                    {!!error &&
                        <>
                            {logEvent(analytics, 'error', { error: error })}
                            <Alert sx={{ margin: 3, marginTop: 5 }} severity="error">{String(error)}</Alert>
                        </>
                    }
                </Container>
            </CssBaseline>
        </ThemeProvider>
    </>
}