import { Grid, TextField, Typography } from '@mui/material'
import { useContext, useEffect, useRef, useState } from 'react'
import { PreferenceCenterContext } from '../../contexts/PreferenceCenter'
import { TCustomAttribute } from '../../types/TCustomAttribute'

export function CustomAttribute({ customAttribute }: { customAttribute: TCustomAttribute, initialValue?: string }) {
    const context = useContext(PreferenceCenterContext)
    const [field, setField] = useState<string | undefined>("")
    const saveTimeout = useRef<NodeJS.Timeout>()

    function handleChangeField(a: any) {
        if (context) {
            const { name, value } = a.currentTarget
            const matching = context.contact.customAttributes.filter(c => c.name === name)

            if (matching.length > 0) {
                matching[0].value = value
            } else {
                context.contact.customAttributes.push({ name: name, value: value })
            }
            setField(value)
            clearTimeout(saveTimeout.current)
            saveTimeout.current = setTimeout(() => context.saveHandle(), 800)
        }
    }

    function setInitialValue() {
        if (context && customAttribute) {
            const matches = context.contact?.customAttributes.filter(ca => ca.name === customAttribute.name)
            if (matches.length === 1) setField(matches[0].value)
        }
    }

    useEffect(setInitialValue, [context, customAttribute])
    useEffect(() => clearTimeout(saveTimeout.current), [])

    return <>
        <Grid container spacing={2}>
            <Grid item display={'flex'} flexDirection={'row'} xs={3} alignItems={'center'} minWidth={200}>
                <Typography> {customAttribute.alias || customAttribute.name}</Typography>
            </Grid>
            <Grid item display={'flex'} flexDirection={'row'} xs={6}>
                <TextField
                    sx={{ textDecoration: 'underline' }}
                    name={customAttribute.name}
                    id={customAttribute.name}
                    variant="standard"
                    value={field}
                    onChange={handleChangeField}
                    InputProps={{ disableUnderline: true }}
                />
            </Grid>
        </Grid>
    </>
}
