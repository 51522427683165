import { Box, Typography } from "@mui/material"

export function SectionTitle({ title, description }: { title: string, description: string }) {
    return <>
        <Box sx={{ paddingBottom: 3, paddingTop: 2 }}>
            <Typography fontSize={24} color={'primary'}>
                {title}
            </Typography>
            <Typography fontSize={14} sx={{ paddingBottom: 1 }} color={'primary'}>
                {description}
            </Typography>
        </Box>
    </>
}