import { Container, Grid, Link, useTheme } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { PreferenceCenterContext } from "../../contexts/PreferenceCenter"
import { Translations } from "../../translations"
import { getLocalized } from "../reusable/ResellerLogo"

export function PartnerLinks(): JSX.Element {
    const context = useContext(PreferenceCenterContext)
    const [privacyPolicyUrl, setPrivacyPolicyUrl] = useState<URL>()
    const [termsOfServiceUrl, setTermsOfServiceUrl] = useState<URL>()
    const theme = useTheme()
    const { t: translate } = useTranslation()

    function getLinks(): void {
        if (context?.partnerBrand) {
            const privUrl = getLocalized(context.partnerBrand.data?.url?.privacy_url)
            const termsUrl = getLocalized(context.partnerBrand.data?.url?.terms_url)

            if (privUrl) setPrivacyPolicyUrl(new URL(privUrl))
            if (termsUrl) setTermsOfServiceUrl(new URL(termsUrl))
        }
    }

    useEffect(getLinks, [context?.partnerBrand])

    return <>
        <Container sx={{ marginTop: 0 }}>
            <Grid container>
                <Grid item sx={{ display: 'flex', paddingRight: 1 }}>
                    {!!privacyPolicyUrl &&
                        <Link fontSize={14} href={privacyPolicyUrl.href} color={theme.palette.primary.light}>
                            {translate(Translations.privacy_policy)}
                        </Link>
                    }
                </Grid>
                <Grid item sx={{ display: 'flex' }}>
                    {!!termsOfServiceUrl &&
                        <Link fontSize={14} href={termsOfServiceUrl.href} color={theme.palette.primary.light}>
                            {translate(Translations.terms_of_service)}
                        </Link>
                    }
                </Grid>
            </Grid>
        </Container>
    </>
}