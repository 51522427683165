import { Box, Grid, Switch, Typography } from '@mui/material'
import { logEvent } from 'firebase/analytics'
import { useContext, useEffect, useState } from 'react'
import { analytics } from '../../config'
import { PreferenceCenterContext } from '../../contexts/PreferenceCenter'
import { InterestModel } from '../../models/Interest'
import { TInterest } from '../../types/TInterest'

export function Interest({ interest }: { interest: TInterest }) {
    const context = useContext(PreferenceCenterContext)
    const [subState, setSubState] = useState<boolean>(false)
    const [listInterest, setListInterest] = useState<InterestModel>()

    function setState(s?: boolean) {
        const newState = s !== undefined ? s : !subState
        if (context) {
            if (newState === true) {
                // add to subscriptions
                if (!context.contact.interests.includes(interest.name)) {
                    context.contact.interests.push(interest.name)
                }
            } else {
                // remove from subscriptions
                if (context.contact.interests.includes(interest.name)) {
                    context.contact.interests = context.contact.interests.filter(s => s !== interest.name)
                }
            }
        }
        setSubState(newState)
    }

    function toggleState() {
        const newState = !subState
        setState(newState)

        if (context) {
            context?.saveHandle()
            logEvent(analytics, 'change_interest', {
                name: interest.name,
                state: newState === true ? 'interested' : 'not_interested'
            })
        }
    }

    function setInitialState() {
        if (context?.contact?.interests && interest) setSubState(context.contact.interests.includes(interest.name))
    }

    function getListInterest() {
        if (context?.interests && interest) {
            const matching = context.interests.filter(s => s.name === interest.name)
            if (matching.length > 0) {
                setListInterest(matching[0])
            }
        }
    }

    useEffect(setInitialState, [context?.contact?.interests, interest])
    useEffect(getListInterest, [context?.interests, interest])

    return <>
        <Grid key={`gc${interest.alias}`} container spacing={2}>
            <Grid key={`g1${interest.alias}`} item xs={10} display='flex' alignItems={'center'}>
                <Box>
                    <Typography fontWeight={100}>{(interest.alias || listInterest?.alias) || interest.name}</Typography>
                </Box>
            </Grid>
            <Grid key={`g2${interest.alias}`} item xs={2} display='flex' flexDirection={'row-reverse'}>
                <Switch
                    disabled={context?.globalUnsubd || false}
                    checked={subState}
                    onClick={toggleState}
                />
            </Grid>
        </Grid>
    </>
}