import { logEvent } from "firebase/analytics"
import { analytics, pcApi } from "../config"
import { TToken } from "../types/TToken"


export class InterestModel {
    readonly name: string
    readonly alias?: string
    readonly description?: string

    private constructor({ name, alias, description }: InterestModel) {
        this.name = name
        this.alias = alias
        this.description = description
    }

    static async list({ token }: TToken): Promise<InterestModel[]> {
        return pcApi({ action: 'getInterests', cryptotext: token })
            .then((response: any) => {
                return response.data.map((sub: any) => {
                    return new InterestModel({
                        name: sub.name,
                        alias: sub.alias,
                        description: sub.description,
                    })
                })
            })
            .catch((error: any) => {
                logEvent(analytics, 'error', error)
                throw error
            })
    }

}
