import i18next from 'i18next'
import LanguageDetector from "i18next-browser-languagedetector"
import LocizeBackend from "i18next-locize-backend"
import ReactDOM from 'react-dom/client'
import { initReactI18next } from 'react-i18next'
import App from './App'
import './index.css'
import reportWebVitals from './reportWebVitals'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
)

i18next
    .use(LocizeBackend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        backend: {
            projectId: process.env.REACT_APP_LOCIZE_PORJECT_ID,
            referenceLng: "en",
            lng: "en"
        },
        detection: {
            order: ['localStorage', 'navigator'],
            lookupQuerystring: 'locale',
            lookupCookie: 'locale',
            lookupLocalStorage: 'locale'
        },
        fallbackLng: "en",
        ns: "production"
    })

Sentry.init({
    dsn: "https://2aa916f672ab42ef94ae7e70429c3441@o379493.ingest.sentry.io/4504850311675904",
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    integrations: [
        new BrowserTracing(),
        new Sentry.Replay({
            // Additional SDK configuration goes in here, for example:
            maskAllText: true,
            blockAllMedia: true,
        }),
    ],
})

root.render(
    <App />
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
