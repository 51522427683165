import { Box, Container } from '@mui/material'
import { useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { PreferenceCenterContext } from '../../contexts/PreferenceCenter'
import { Translations } from '../../translations'
import { TInterest } from '../../types/TInterest'
import { Line } from '../reusable/Line'
import { SectionTitle } from '../reusable/SectionTitle'
import { Interest } from "../reusable/Interest"

/**
 * Lists all Subscriptions for the Contact
 * 
 * @param param0 
 * @returns 
 */
export function Interests(): JSX.Element {
    const context = useContext(PreferenceCenterContext)
    const { t: translate } = useTranslation()
    const [filteredInterests, setFilteredInterests] = useState<TInterest[]>()

    function filterInterests() {
        function isValidInterest(interestName: string): boolean | undefined {
            if (context?.interests) {
                const found = context?.interests.filter(f => f.name === interestName)
                return found.length > 0
            }
        }
        if (context && context.preferenceCenter?.interests) {
            setFilteredInterests(
                context.preferenceCenter.interests.filter(s => isValidInterest(s.name))
            )
        }
    }

    useEffect(filterInterests, [context])

    return <>
        {filteredInterests && filteredInterests.length > 0 && !!context?.interests &&
            <Container sx={{ marginTop: 5 }}>
                <SectionTitle
                    title={translate(Translations.interests_title)}
                    description={translate(Translations.interests_description, { companyName: context.account.name })}
                />
                <Box className='interests'>
                    {filteredInterests.map(
                        (interest: TInterest) => <>
                            <Interest key={interest.name} interest={interest} />
                            <Line key={`line-${interest.name}`} />
                        </>
                    )}
                </Box>
            </Container>
        }
    </>
}
