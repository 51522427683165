import { logEvent } from "firebase/analytics"
import { analytics, pcApi } from "../config"
import { TListCustomAttribute } from "../types/TListCustomAttribute"


export class ListModel {
    readonly id: number
    readonly status: string
    readonly language: string
    readonly customAttributes: TListCustomAttribute[]

    private constructor({ id, status, language, customAttributes }: ListModel) {
        this.id = id
        this.status = status
        this.language = language
        this.customAttributes = customAttributes
    }

    static async get({ token }: { token: string }): Promise<ListModel> {
        return pcApi({ action: 'getList', cryptotext: token })
            .then((response: any) => {
                const data = response.data
                return new ListModel({
                    id: data.id,
                    status: data.status,
                    language: data.language,
                    customAttributes: data.customAttributes
                })
            })
            .catch((error: any) => {
                logEvent(analytics, 'error', error)
                throw error
            })
    }
}
