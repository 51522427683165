import { logEvent } from "firebase/analytics"
import { analytics, brandUrl } from "../config"
import { AccountModel } from "./Account"
const fetch = require('fetch-retry')(global.fetch)


export class BrandModel {
    readonly data: any

    private constructor(object: any) {
        this.data = object
    }

    static async get({ account, url }: { account?: AccountModel, url?: URL }) {
        const brandId = account ? account.id : url ? url.hostname : null

        return fetch(`${brandUrl}/brands/${brandId}/compiledBrand.json`, { method: 'GET', retry: 3, retryDelay: 333 })
            .then(async (fetchResponse: Response) => {
                return fetchResponse.json()
            })
            .then((response: any) => {
                return new BrandModel(response)
            })
            .catch((error: any) => {
                logEvent(analytics, 'error', error)
                throw error
            })
    }

    static async getBrandUrl({ account }: { account: AccountModel }) {
        return fetch(`${brandUrl}/brands/${account.lineage}/brandUrl`, { method: 'GET', retry: 3, retryDelay: 333 })
            .then(async (fetchResponse: Response) => {
                return fetchResponse.json()
            })
            .then((response: any) => {
                return new URL(response.url)
            })
            .catch((error: any) => {
                logEvent(analytics, 'error', error)
                throw error
            })
    }
}
