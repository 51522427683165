import React from "react"
import { AccountModel } from "../models/Account"
import { BrandModel } from "../models/Brand"
import { ContactModel } from "../models/Contact"
import { InterestModel } from "../models/Interest"
import { ListModel } from "../models/List"
import { PreferenceCenterModel } from "../models/PreferenceCenter"

type TPreferenceCenter = {
    preferenceCenter?: PreferenceCenterModel
    contact: ContactModel
    account: AccountModel
    brand: BrandModel
    list?: ListModel
    interests: InterestModel[]
    saveHandle: Function
    listUnsubd?: boolean
    globalUnsubd?: boolean
    partnerUrl?: URL
    partnerBrand?: BrandModel
}

export const PreferenceCenterContext = React.createContext<TPreferenceCenter | null>(null)