import { Box, Container, Grid, Typography } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PreferenceCenterContext } from '../../contexts/PreferenceCenter'
import { Translations } from '../../translations'
import { TCustomAttribute } from '../../types/TCustomAttribute'
import { CustomAttribute } from "../reusable/CustomAttribute"
import { Line } from '../reusable/Line'
import { SectionTitle } from '../reusable/SectionTitle'

export function PersonalInfo(): JSX.Element {
    const context = useContext(PreferenceCenterContext)
    const { t: translate } = useTranslation()
    const [filteredCustomAttributes, setFilteredCustomAttributes] = useState<TCustomAttribute[]>()

    function filterCustomAttributes() {
        if (context && context.list && context.preferenceCenter?.customAttributes) {
            setFilteredCustomAttributes(
                context.preferenceCenter.customAttributes.filter(s => {
                    if (context?.list?.customAttributes) {
                        const found = context?.list.customAttributes.filter(f => f.name === s.name)
                        return found.length > 0
                    }
                    return false
                })
            )
        }
    }

    useEffect(filterCustomAttributes, [context])

    return <>
        {context &&
            <Container>
                <SectionTitle
                    title={translate(Translations.custom_attributes_title)}
                    description={translate(Translations.custom_attributes_description, { companyName: context.account.name })}
                />
                <Box className='personalinfo'>
                    <Grid container spacing={2} sx={{ paddingBottom: 1 }}>
                        <Grid item display={'flex'} flexDirection={'row'} xs={3} alignItems={'center'} minWidth={200}>
                            <Typography>{translate(Translations.email_address)}</Typography>
                        </Grid>
                        <Grid item display={'flex'} flexDirection={'row'} xs={6}>
                            {context.contact.email}
                        </Grid>
                    </Grid>
                    <Line />

                    {filteredCustomAttributes && filteredCustomAttributes.length > 0 &&
                        <>
                            {filteredCustomAttributes.map((customAttribute: TCustomAttribute) =>
                                <>
                                    <CustomAttribute key={customAttribute.name} customAttribute={customAttribute} />
                                    <Line />
                                </>
                            )}
                        </>
                    }
                </Box>
            </Container>
        }
    </>
}
